import React from "react";
import Image from "next/dist/client/image";
const LogoBlack = () => {
  return (
    <Image
      src={`${process.env.NEXT_PUBLIC_DIRECTUS_URL}/assets/2f4e121d-5da2-4b16-a80c-0820910dbc8a`}
      alt="hortiturkey logo"
      width={157}
      height={34}
      layout="fixed"
      priority
    />
  );
};

export default LogoBlack;
