import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import Image from "next/image";
import Link from "next/link";

export default function LanguageSwicther(props) {
  const activeLanguage = props.active;

  const localeLinks = {
    en: props.localeLinks.en || "/en",
    ru: props.localeLinks.ru || "/ru",
    es: props.localeLinks.es || "/es",
    tr: props.localeLinks.tr || "/",
  };

  const locales = props.locales;
  const localeItems = {
    en: (
      <Menu.Item key="en">
        {() => (
          <Link locale="en" href={localeLinks.en}>
            <a className="flex flex-row items-center px-4 py-2 hover:bg-gray-100 hover:text-gray-700">
              <div className="relative w-4 h-3 mr-2">
                <Image
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                  alt="ingilizce"
                  src="/english_flag.png"
                />
              </div>
              English
            </a>
          </Link>
        )}
      </Menu.Item>
    ),
    ru: (
      <Menu.Item key="ru">
        {() => (
          <Link locale="ru" href={localeLinks.ru}>
            <a className="flex flex-row items-center px-4 py-2 hover:bg-gray-100 hover:text-gray-700">
              <div className="relative w-4 h-3 mr-2">
                <Image
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                  alt="ingilizce"
                  src="/russian_flag.png"
                />
              </div>
              Pусский
            </a>
          </Link>
        )}
      </Menu.Item>
    ),
    es: (
      <Menu.Item key="es">
        {() => (
          <Link locale="es" href={localeLinks.es}>
            <a className="flex flex-row items-center px-4 py-2 hover:bg-gray-100 hover:text-gray-700">
              <div className="relative w-4 h-3 mr-2">
                <Image
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                  alt="spanish"
                  src="/spanish_flag.png"
                />
              </div>
              Español
            </a>
          </Link>
        )}
      </Menu.Item>
    ),
    tr: (
      <Menu.Item key="tr">
        {() => (
          <Link locale="tr" href={localeLinks.tr}>
            <a className="flex flex-row items-center px-4 py-2 hover:bg-gray-100 hover:text-gray-700">
              <div className="relative w-4 h-3 mr-2">
                <Image
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                  alt="türkçe"
                  src="/turkish-flag.png"
                />
              </div>
              Türkçe
            </a>
          </Link>
        )}
      </Menu.Item>
    ),
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center justify-center w-10 h-8 transition-all ease-in bg-gray-100 rounded-md sm:h-10 sm:w-14 hover:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-100 focus:ring-primaryLight duration-50">
          {activeLanguage.toUpperCase()}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 w-auto mt-2 origin-top-right bg-white rounded-md shadow-sm ring-1 ring-primaryLight focus:outline-none">
          <div className="py-1">
            {locales.map((locale) => {
              if (locale != activeLanguage) {
                return localeItems[locale];
              }
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
