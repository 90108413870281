import Link from "next/link";

const PrimaryGreenButton = (props) => {
  const {
    type = "button",
    href,
    full = false,
    size = "small",
    handler,
    handling = false,
    disabled = false,
  } = props;

  if (type === "button") {
    return (
      <button
        onClick={handler}
        disabled={disabled}
        className={`flex justify-center items-center
          bg-primaryLight px-6 rounded-md text-neutral-800 hover:bg-opacity-80 transition-all duration-100 ease-in whitespace-nowrap disabled:bg-slate-600 disabled:text-slate-100 ${
            full ? "w-full" : ""
          } ${size === "large" ? "py-3" : "py-2"}
        `}
      >
        {handling && (
          <svg
            className="w-6 h-6 mr-3 -ml-1 text-white animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        {!handling && props.children}
      </button>
    );
  } else {
    return (
      <Link href={href}>
        <a
          className={`
          bg-primaryLight px-6 rounded-md text-neutral-800 hover:bg-opacity-80 transition-all duration-100 ease-in text-center whitespace-nowrap ${
            full ? "w-full" : ""
          } ${size === "large" ? "py-3" : "py-2"}
        `}
        >
          {props.children}
        </a>
      </Link>
    );
  }
};

export default PrimaryGreenButton;
